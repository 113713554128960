<template>
  <loading-spinner v-if="state.loading" />
  <div v-else>
    <the-header-card @refresh="loadTemplateInfo">
      <va-button @click="goBack" >
        Back
      </va-button>
    </the-header-card>
    <va-card>
      <va-card-title>
        {{ templateId }} - Template
      </va-card-title>
      <va-card-content>
        <json-viewer :value="state.template" />
      </va-card-content>
    </va-card>
  </div>
</template>

<script setup>
import { inject, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TheHeaderCard from "@/components/TheHeaderCard.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { callFetchTemplateById } from "@/api/licensing";
import JsonViewer from "@/components/JsonViewer.vue"; // Assume you have this component

const route = useRoute();
const router = useRouter();
const handleError = inject("handleError");

const state = reactive({
  loading: false,
  template: null,
});

const templateId = route.params.id; 

const loadTemplateInfo = async () => {
  state.loading = true;
  try {
    const response = await callFetchTemplateById(templateId);
    state.template = response.data;
  } catch (error) {
    handleError(error);
  }
  state.loading = false;
};

const goBack = () => {
  router.back();
};

onMounted(async () => {
  await loadTemplateInfo();
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
