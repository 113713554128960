<template>
  <div class="json-container">
    <div v-if="state.isLoading" class="loading-message">
      Loading JSON<span class="dots">...</span>
    </div>
    <json-viewer :value="JsonTemplate" theme="my-awesome-json-theme"   :expand-depth=5   />
  </div>
</template>
  
  <script setup>
  import { computed, reactive, watchEffect } from 'vue';
  import { JsonViewer } from 'vue3-json-viewer';
    import 'vue-json-viewer/style.css'
  
  const props = defineProps({
    value: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  });
  
  const state = reactive({
    isLoading: computed(() => props.isLoading),
    template: ''
  });
  
  const fetchJsonTemplate = async () => {
    console.log(props.value)
    state.template =props.value ;
  };
  
  const JsonTemplate = computed(() => state.template);
  
  watchEffect(() => {
    console.log(props.value);
    fetchJsonTemplate();
  });
  </script>
  
  <style scoped>
       

        .loading-message {
        font-size: 1rem;
        font-weight: normal;
        color: #f8f8f2;
        }

        .dots::after {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        animation: dots 1.5s steps(5, end) infinite;
        }

        @keyframes dots {
        0%, 20% {
            content: '';
        }
        40% {
            content: '.';
        }
        60% {
            content: '..';
        }
        80%, 100% {
            content: '...';
        }
        }
</style>
  